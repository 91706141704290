<template lang="pug">
  .table
    AppTable.app-table(:columns="columns", :data="listData", border)
      template(
        slot-scope="{ row, index }"
        slot="type"
      )
        span {{ $t(`campaignType.${row.type}`) }}
      //- template(
      //-   slot-scope="{ row, index }"
      //-   slot="createdAt"
      //- )
      //-   span {{ timeFormat(row.createdAt)  }}
      template(
        slot-scope="{ row, index }"
        slot="launchTime"
      )
        span {{ timeFormat(row.launchTime, 'YYYY-MM-DD HH:mm')  }}
      template(
        slot-scope="{ row, index }"
        slot="name"
      )
        span.pointer(
          @click="searchCampaign(row.name)"
        ) {{ row.name }}
      template(
        slot-scope="{ row, index }"
        slot="closeTime"
      )
        span {{ timeFormat(row.closeTime, 'YYYY-MM-DD HH:mm')  }}
      template(
        slot-scope="{ row, index }"
        slot="startTime"
      )
        span {{ timeFormat(row.startTime, 'YYYY-MM-DD HH:mm')  }}
      template(
        slot-scope="{ row, index }"
        slot="endTime"
      )
        span {{ timeFormat(row.endTime, 'YYYY-MM-DD HH:mm')  }}

      template(
        slot-scope="{ row, index }"
        slot="periods"
      )
        span {{ row.periods  }}

      template(
        slot-scope="{ row, index }"
        slot="periodPrize"
      )
        span {{ row.type === 'rankList' ? currencyFormat(row.periodPrize, userInfo) : '?' }} / {{ row.type === 'rankList' ? currencyFormat(row.periodPrize * row.periods, userInfo) : '?' }} / {{ row.type === 'rankList' ? row.ranking : '?' }}

      //- template(
      //-   slot-scope="{ row, index }"
      //-   slot="totalPrize"
      //- )
      //-   span {{ row.type === 'rankList' ? currencyFormat(row.periodPrize * row.periods, userInfo) : '?' }}

      template(
        slot-scope="{ row, index }"
        slot="ranking"
      )
        span {{ row.type === 'rankList' ? row.ranking : '?' }}

      template(
        slot-scope="{ row, index }"
        slot="status"
      )
        span(:class="getStyle(row)") {{ $t(`Campaign ${getStatus(row)}`) }}

      template(
        slot-scope="{ row, index }"
        slot="actions"
      )
        Button(
          type="success",
          size='small',
          icon="md-create",
          @click="editCampaign(row)"
        )

    CampaignEditor(
      :visible="campaignEditorVisible"
      :login-user-info="userInfo",
      :form-data="campaignData",
      :edit-type="campaignEditorType"
      :on-update="onCampaignUpdate"
      :on-close="closeCampaignEditor"
    )
</template>
<script>
import Currency from '~m/currency'
import Time from '~m/time'
import AppTable from '~c/app-table'
import CampaignEditor from '~v/campaign/campaign-editor'
import CampaignRank from '~v/campaign/campaign-rank'
import { mapActions } from 'vuex'
import to from 'await-to-js'
import moment from 'moment'

export default {
  name: 'campaign-table',
  mixins: [
    Currency,
    Time
  ],
  components: {
    AppTable,
    CampaignEditor,
    CampaignRank
  },
  data () {
    return {
      campaignData: null,
      campaignEditorType: '',
      campaignEditorVisible: false
    }
  },
  props: {
    listData: Array,
    hasOperator: Boolean,
    userInfo: Object
  },
  watch: {
  },
  computed: {
    // 遊戲表單
    columns () {
      let columns = [
        {
          title: 'ID',
          key: 'campaignId',
          width: 64,
          align: 'center'
        },
        // {
        //   title: this.$t('Created at'),
        //   slot: 'createdAt',
        //   align: 'center',
        //   ellipsis: true
        // },
        {
          title: this.$t('Campaign name'),
          slot: 'name',
          width: 130
        },
        {
          title: this.$t('Campaign type'),
          slot: 'type',
          align: 'center',
          width: 100
        },
        {
          title: this.$t('Launch at'),
          slot: 'launchTime',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Close at'),
          slot: 'closeTime',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Start at'),
          slot: 'startTime',
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('End at'),
          slot: 'endTime',
          align: 'center',
          ellipsis: true
        },
        {
          title: `${this.$t('Campaign period prize')} / ${this.$t('Campaign total prize')} / ${this.$t('Campaign period ranking')}`,
          slot: 'periodPrize',
          align: 'center',
          ellipsis: true
        },
        // {
        //   title: this.$t('Campaign total prize'),
        //   slot: 'totalPrize',
        //   align: 'center',
        //   ellipsis: true
        // },
        {
          title: this.$t('Campaign round count'),
          slot: 'periods',
          align: 'center',
          ellipsis: true,
          width: 85
        },
        // {
        //   title: this.$t('Campaign period ranking'),
        //   slot: 'ranking',
        //   align: 'center',
        //   ellipsis: true,
        //   width: 85
        // },
        {
          title: this.$t('Campaign status'),
          slot: 'status',
          align: 'center',
          ellipsis: true,
          width: 85
        },
        {
          title: this.$t('Campaign switch'),
          key: 'actived',
          // 無法用 slot template 取得點擊後的 instance 用 render 取代
          render: this.renderSwitch,
          align: 'center',
          width: 85
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 66
        },
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(CampaignRank, {
              props: {
                campaignData: params.row
              }
            })
          }
        }
      ]

      return columns
    }
  },
  mounted () {
    //
  },
  methods: {
    ...mapActions('campaign', {
      campaignUpdate: 'update',
      findCampaign: 'findOne'
    }),
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('campaign/update', { id: row.campaignId, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    },
    async onCampaignUpdate () {
      if (!this.campaignData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeCampaignEditor()
      }

      let dataUpdate = _.pick(this.campaignData, ['operators', 'provider', 'games'])
      let [err] = await to(this.campaignUpdate({ id: this.campaignData.campaignId, data: dataUpdate }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Edit fail'))
      }

      this.$Message.success(this.$t('Edit success'))
      // this.findGame(data)
      this.closeCampaignEditor()
    },

    async editCampaign (row) {
      this.campaignEditorType = 'edit'
      this.campaignEditorVisible = true
      this.campaignData = _.cloneDeep(row)
    },

    closeCampaignEditor () {
      this.campaignEditorVisible = false
      setTimeout(() => {
        this.campaignEditorType = null
        this.campaignData = null
      }, 500)
    },

    getStatus (row) {
      const now = moment()
      if (now.isBefore(row.startTime)) {
        return 'Ready'
      } else if (now.isBefore(row.endTime) && now.isAfter(row.startTime)) {
        return 'InProgress'
      } else {
        return 'Finished'
      }
    },

    getStyle (row) {
      return `campaign-${this.getStatus(row).toLowerCase()}`
    },
    searchCampaign (name) {
      this.$emit('after-click-name', name)
    }
  }
}
</script>
<style lang="sass" scoped>
.campaign-ready
  color: #19be6b

.campaign-inprogress
  color: #ed4014

.campaign-finished
  color: #ccc
.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
</style>
