<template lang="pug">
.content(
  v-infinite-scroll="fetchData"
  infinite-scroll-disabled="isCampaignBusy"
  :infinite-scroll-distance="scrollDistance"
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Affix(
      :offset-top="62"
      @on-change="searchAffixChange"
      :class="affixClass"
    )
      Row
        Col(span='24')
          DatePicker.app-tool.date(
            v-model="date"
            :disabled="campaignListLoading"
            type="date"
            :options="dateOptions"
            :placeholder="$t('Select date')"
            @on-change="onDateChange"
          )

          Select.app-tool(
            v-model="type"
            :disabled="campaignListLoading"
            :placeholder="$t('Campaign type')"
            prefix="ios-flash"
            @on-change="onCampaignTypeChange"
          )
            Option(v-for="(option, index) in campaignTypes" :value="option.value" :key="`cts-${index}`") {{option.label}}

          Input.app-tool(
            v-model="search"
            :disabled="campaignListLoading"
            icon="ios-search"
            clearable
            :placeholder="$t('Search campaign name')"
            @on-change='onSearchInputChange'
          )

          Button.app-tool(
            icon="md-add"
            type="primary"
            style="float: right;"
            @click="newCampaign()"
          ) {{$t('Add new campaign')}}
    //- 主表單內容
    Row(:style="{marginTop: '16px'}")
      Col(span='24', v-if="campaignList.length || !campaignListLoading")
        CampaignTable(
          :list-data="campaignList"
          :user-info="userInfo"
          @after-click-name="afterClickName"
        )

      Col(span='24', class="table-loading-col" v-if="campaignListLoading")
        Spin(fix)
          Icon(type="ios-loading" size=18 class="spin-icon")

    CampaignEditor(
      :visible="campaignEditorVisible"
      :login-user-info="userInfo"
      :form-data="campaignData"
      :edit-type="campaignEditorType"
      :on-update="onCampaignUpdate"
      :on-close="closeCampaignEditor"
    )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CampaignTable from '~v/campaign/campaign-table'
import CampaignEditor from '~v/campaign/campaign-editor'
import Scroll from '~m/scroll'
import RouterEvents from '~m/router-events'
import moment from 'moment'
import to from 'await-to-js'

export default {
  name: 'game',
  components: {
    CampaignTable,
    CampaignEditor
  },
  mixins: [Scroll, RouterEvents],
  data () {
    return {
      operator: '',
      date: moment().startOf('d').format('YYYY-MM-DD'),
      search: '',
      type: 'all',
      campaignData: null,
      campaignEditorType: '',
      campaignEditorVisible: false,
      campaignTypes: [
        { value: 'all', label: this.$t('All campaign types') },
        { value: 'rankList', label: this.$t('campaignType.rankList') },
        { value: 'cumulativeBetting', label: this.$t('campaignType.cumulativeBetting') },
        { value: 'fixedBetting', label: this.$t('campaignType.fixedBetting') }
      ],
      dateOptions: {
        shortcuts: [
          {
            text: this.$t('Today'),
            value () {
              return moment().startOf('d').format('YYYY-MM-DD')
            }
          },
          {
            text: this.$t('Yesterday'),
            value () {
              return moment().add(-1, 'd').startOf('d').format('YYYY-MM-DD')
            }
          },
          {
            text: this.$t('Last week'),
            value () {
              return moment().add(-1, 'w').startOf('d').format('YYYY-MM-DD')
            }
          }
        ]
      }
    }
  },
  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      // pid 不一樣要更新 operator list
      this.resetSelector()
    }
  },
  // 所有內容的多數 data 來自於 vuex 的 state 以 module 裡的 getter 的方式嵌入 computed 中
  computed: {
    // 嵌入營運商的 state 資料
    ...mapGetters('operator', {
      allOperatorsForSelector: 'getAllForSelector'
    }),
    allOperators () {
      return this.allOperatorsForSelector.filter(op => op.actived)
    },
    ...mapGetters('provider', {
      allProviders: 'getAllForSelector'
    }),

    // 嵌入 campaign 的 state 資料
    ...mapGetters('campaign', {
      campaignList: 'getListData',
      campaignListLoading: 'getLoadingStatus',
      hasNextCampaign: 'hasNext'
    }),
    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ]),
    hasOperator () {
      return /[0-9]+/.test(`${this.operator}`)
    },
    // 遊戲內容是否在讀取
    isCampaignBusy () {
      return this.campaignListLoading || !this.hasNextCampaign
    }
  },

  methods: {
    ...mapActions('campaign', { findCampaign: 'find', resetCampaign: 'reset', createCampaign: 'create' }),

    updateRoute ({ path, query }) {
      this.$router.push({ path })
      this.$router.replace({ path, query })
    },

    onDateChange (val) {
      let { path, query } = this.$route
      query.date = val
      this.updateRoute({ path, query })
    },

    onCampaignTypeChange (val) {
      let { path, query } = this.$route
      query.type = val
      this.updateRoute({ path, query })
    },

    // search input 用
    onSearchInputChange (event) {
      clearTimeout(this.delaySearch)
      this.delaySearch = setTimeout(() => {
        let { path, query } = this.$route
        query.search = this.search
        this.updateRoute({ path, query })
      }, 1000)
    },
    // 向 API 取得 data
    // infinity scroll 需綁定
    fetchData (reset = false) {
      let { search, type } = this.$route.query
      search = search === '' ? null : search
      type = ['', 'all'].includes(type) ? null : type
      const date = moment(this.date).valueOf()
      if (reset) {
        this.resetCampaign({ search, date, type })
      }
      this.findCampaign({ search, date, type })
    },
    // 依據 route 重設所有過濾條件
    async resetSelector () {
      this.type = this.$route.query.type || 'all'
      this.fetchData(true)
    },

    async newCampaign () {
      this.campaignData = {
        name: '',
        content: '',
        type: 'rankList',
        startTime: moment().startOf('d').toDate(),
        endTime: moment().startOf('d').add(7, 'd').toDate(),
        launchTime: moment().startOf('d').toDate(),
        closeTime: moment().startOf('d').add(10, 'd').toDate(),
        startTimePicker: moment().startOf('d').toDate(),
        endTimePicker: moment().startOf('d').add(7, 'd').toDate(),
        launchTimePicker: moment().startOf('d').toDate(),
        closeTimePicker: moment().startOf('d').add(10, 'd').toDate(),
        operators: this.allOperators.map(o => o._id),
        provider: this.allProviders[0]._id,
        cycleTime: 7,
        rewardType: 'point',
        condition: 200,
        model: 'BetRecord',
        sort: '-win',
        ranking: 100,
        rewards: [{ rank: 1, prize: 10000 }],
        games: []
      }

      this.campaignEditorType = 'new'
      this.campaignEditorVisible = true
    },
    async onCampaignUpdate () {
      if (!this.campaignData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeCampaignEditor()
      }

      this.campaignData.startTime = this.campaignData.startTime.valueOf()
      this.campaignData.endTime = this.campaignData.endTime.valueOf()
      this.campaignData.launchTime = this.campaignData.launchTime.valueOf()
      this.campaignData.closeTime = this.campaignData.closeTime.valueOf()

      let [err] = await to(this.createCampaign({ data: this.campaignData }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Create campaign error'))
      }

      this.$Message.success(this.$t('Create campaign successful'))
      this.closeCampaignEditor()

      this.fetchData(true)
    },

    closeCampaignEditor () {
      this.campaignEditorVisible = false
      setTimeout(() => {
        this.campaignEditorType = null
        this.campaignData = null
      }, 500)
    },
    afterClickName (name) {
      if (this.search !== name) {
        this.search = name
        this.onSearchInputChange()
      }
    }
  },
  mounted () {
    this.resetSelector()
  },
  beforeDestroy () {
    this.resetCampaign()
  }
}

</script>

<style lang="sass">
</style>
