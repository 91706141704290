<template lang="pug">
.campaign(
  v-infinite-scroll="fetchData"
  infinite-scroll-disabled="isCampaignRankBusy"
  :infinite-scroll-distance="scrollDistance"
)
  AppTable.campaign-campaignRank.app-table(:columns="campaignRankCols", :data="campaignRankList", border, v-if="campaignRankList.length")
    template(slot-scope="{ row, index }", slot="index")
      span {{ row.campaignId }}
    template(slot-scope="{ row, index }", slot="startTime")
      span {{ timeFormat(row.startTime) }}
    template(slot-scope="{ row, index }", slot="endTime")
      span {{ timeFormat(row.endTime) }}
    template(slot-scope="{ row, index }", slot="status")
      span(:class="getStyle(row)") {{ $t(`Campaign ${row.status}`) }}
    template(slot-scope="{ row, index }", slot="hasDraw")
      Icon(:type="row.hasDraw ? 'md-checkmark' : 'md-close'", :style="{color: row.hasDraw ? '#19be6b' : '#808695'}")

  Col.table-loading-col(span='24', v-if="campaignRankListLoading")
    Spin(fix, :style="{ backgroundColor: 'transparent', marginTop: '0' }")
      Icon(type="ios-loading" size=18 class="spin-icon")

  CampaignRankEditor(
    :form-data="campaignRankData2Update",
    :edit-type="campaignRankEditType"
    :on-update="onCampaignRankUpdate"
    :visible="campaignRankEditorVisible"
    :on-close="closeCampaignRankEditor"
  )

</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Currency from '~m/currency'
import AppTable from '~c/app-table'
import Time from '~m/time'
import Scroll from '~m/scroll'
import NumberUtil from '~m/number-util'
import CampaignRankEditor from '~v/campaign/campaign-rank-editor'
import CampaignRankList from '~v/campaign/campaign-rank-list'
import to from 'await-to-js'

export default {
  name: 'campaign-rank',
  data () {
    return {
      campaignRankData2Update: null,
      campaignRankEditorVisible: false,
      campaignRankEditType: 'edit'
    }
  },
  props: {
    campaignData: Object
  },
  mixins: [Currency, Scroll, NumberUtil, Time],
  components: { CampaignRankEditor, CampaignRankList, AppTable },
  watch: {
  },
  computed: {
    ...mapGetters('auth', ['userInfo']),
    ...mapGetters('campaignRank', { campaignRankList: 'getListData', campaignRankListLoading: 'getLoadingStatus', hasNextCampaignRank: 'hasNext' }),
    ...mapGetters('operator', {
      allOperatorsForSelector: 'getAllForSelector'
    }),
    allOperators () {
      return this.allOperatorsForSelector.filter(op => op.actived)
    },
    campaignRankCols () {
      return [
        {
          key: 'campaignRankId',
          width: 80,
          align: 'center'
        },
        {
          title: this.$t('Campaign name'),
          key: 'name'
        },
        {
          title: this.$t('Campaign round count'),
          key: 'code'
        },
        {
          title: this.$t('Start at'),
          slot: 'startTime',
          width: 150
        },
        {
          title: this.$t('End at'),
          slot: 'endTime',
          width: 150
        },
        {
          title: this.$t('Campaign rank status'),
          slot: 'status',
          align: 'center',
          width: 75
        },
        {
          title: this.$t('Campaign rank has draw'),
          slot: 'hasDraw',
          align: 'center',
          width: 65
        },
        {
          title: this.$t('Campaign switch'),
          key: 'actived',
          // 無法用 slot template 取得點擊後的 instance 用 render 取代
          render: this.renderSwitch,
          align: 'center',
          width: 85
        },
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            const rewards = this.campaignData.rewards
            const campaignLinkId = params.row.campaignRankId
            return h(CampaignRankList, {
              props: {
                campaignLinkId,
                rewards
              }
            })
          }
        }
      ]
    },
    isCampaignRankBusy () {
      return this.campaignRankListLoading || !this.hasNextCampaignRank
    }
  },
  methods: {
    ...mapActions('campaignRank', { updateCampaignRank: 'update', findCampaignRanks: 'find', resetCampaignRank: 'resetList', createCampaignRank: 'create' }),
    fetchData () {
      const { campaignId } = this.campaignData
      const query = { campaignId }
      this.findCampaignRanks(query)
    },

    editCampaignRank (row) {
      this.campaignRankData2Update = _.cloneDeep(row)
      this.campaignRankEditType = 'edit'
      this.campaignRankEditorVisible = true
    },

    async onCampaignRankUpdate () {
      if (!this.campaignRankData2Update.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeCampaignRankEditor()
      }

      let dataUpdate = _.pick(this.campaignRankData2Update, ['minStake', 'maxStake', 'releaseLevel', 'odds', 'category', 'initialLevel', 'actived', 'prize'])
      // 強轉數字類型
      this.parseObjectNumber(dataUpdate)
      dataUpdate = { data: dataUpdate }

      let method
      switch (this.campaignRankEditType) {
        case 'edit':
          method = this.updateCampaignRank
          dataUpdate.id = this.campaignRankData2Update.campaignRankId
          break

        case 'new':
          const { campaignId, _id, provider, providerId } = this.campaignData
          let { operator } = this.$route.query
          operator = _.find(this.allOperators, { id: operator })
          dataUpdate.data = {
            ...dataUpdate.data,
            campaignId,
            campaign: _id,
            provider,
            providerId,
            operatorId: operator.id,
            operator: operator._id
          }
          method = this.createCampaignRank
          break
      }

      let [err] = await to(method(dataUpdate))
      if (err) {
        console.log(err)
        const msg = this.campaignRankEditType === 'edit' ? 'Edit campaignRank error' : 'Create campaignRank error'
        return this.$Message.error(this.$t(msg))
      }

      this.$Message.success(this.$t('Edit campaignRank seccess'))
      this.closeCampaignRankEditor()
      // this.$forceUpdate()
      if (this.campaignRankEditType === 'new') {
        await this.resetCampaignRank()
        this.fetchData(true)
      }
    },

    closeCampaignRankEditor () {
      this.campaignRankEditorVisible = false
      setTimeout(() => {
        this.campaignRankData2Update = null
      }, 500)
    },

    getStyle (row) {
      return `campaign-${row.status.toLowerCase()}`
    },

    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('campaignRank/update', { id: row._id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    }
  },

  mounted () {
    this.fetchData()
  },

  beforeDestroy () {
    this.resetCampaignRank()
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px

.campaign-ready
  color: #19be6b

.campaign-inprogress
  color: #ed4014

.campaign-finished
  color: #ccc
</style>
