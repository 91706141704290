<template lang="pug">
  Modal(
    :mask-closable="true",
    :styles="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
  )
    p(slot="header")
      span {{ editType === 'new' ? $t("Add new campaign") : $t("Edit campaign") }}

    Form.modal-editor(
      :model="formData"
      ref="campaignEditorForm"
      :rules="campaignEditorRule"
    )
      //- 編輯內容
      template(v-if="editType === 'edit'")
        .content-part
          FormItem
            Row
              Col.title(span="6")
                h3 ID
              Col.val(span="18")
                span {{formData._id}}
          FormItem(prop="type")
            Row
              Col.title(span="6")
                h3 {{$t('Campaign type')}}
              Col.val(span="18")
                span {{$t(`campaignType.${formData.type}`)}}
          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign name')}}
              Col.val(span="18")
                span {{formData.name}}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign content')}}
              Col.val(span="18")
                span {{formData.content}}

        .content-part
          Divider(orientation="left")
            Icon(
              type="md-create",
              style="margin-right: 6px"
            )
            span {{ $t('Campaign schedule settings') }}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign startTime')}}
              Col.val(span="18")
                span {{ timeFormat(formData.startTime) }}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign endTime')}}
              Col.val(span="18")
                span {{ timeFormat(formData.endTime) }}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign launchTime')}}
              Col.val(span="18")
                span {{ timeFormat(formData.launchTime) }}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign closeTime')}}
              Col.val(span="18")
                span {{ timeFormat(formData.closeTime) }}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign cycleTime')}}
              Col.val(span="18")
                span {{ formData.cycleTime }} {{ formData.type === "rankList" ? $t('Day') : $t('Hour') }}

        .content-part
          Divider(orientation="left")
            Icon(
              type="logo-usd",
              style="margin-right: 6px"
            )
            span {{ $t('Campaign reward settings') }}
          div(v-if="formData.type === 'rankList'")
            FormItem
              Row
                Col.title(span="9")
                  h3 {{$t('Campaign period ranking')}}
                Col.val(span="14" offset="1")
                  span {{ formData.ranking }}

            FormItem
              Row
                Col.title(span="9")
                  h3 {{$t('Campaign model')}}
                Col.val(span="14" offset="1")
                  span {{ formData.model }}

            FormItem
              Row
                Col.title(span="9")
                  h3 {{$t('Campaign sort')}}
                Col.val(span="14" offset="1")
                  span {{ formData.sort }}

          FormItem(prop="condition" v-if="formData.type !== 'cumulativeBetting'")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign condition')}}
              Col.val(span="14" offset="1")
                span {{ formData.condition }}

          FormItem(prop="rewards")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign rewards')}}

              Col(span="14" offset="1")
                .rank-row(v-for="(item, index) in formData.rewards" v-if="formData.type === 'rankList'")
                  Row
                    Col(span="3")
                      span {{ $t("Ranking") }}
                    Col(span="6")
                      InputNumber(
                        :min="1"
                        style="width: 52px;"
                        v-model="formData.rewards[index].rank"
                        :placeholder="$t('Input campaign rank')"
                        :key="`rank-${index}`"
                        disabled
                      )
                    Col(span="3")
                      span {{ $t("Prize") }}
                    Col(span="8")
                      InputNumber(
                        :step="1000"
                        style="width: 100px;"
                        v-model="formData.rewards[index].prize"
                        :placeholder="$t('Input campaign rewards')"
                        :key="`prize-${index}`"
                        disabled
                      )
                    Col(span="4")
                      Button.reward-remove(
                        type="error"
                        icon="md-close"
                        size="small"
                        disabled
                        @click="removeReward(index)"
                      )
                .link-row(v-for="(item, index) in formData.rewards" v-if="formData.type !== 'rankList'")
                  Row
                    Col(span="3")
                      span {{ $t("Cumulative betting") }}
                    Col(span="7")
                      InputNumber(
                        :step="100"
                        style="width: 72px;"
                        v-model="formData.rewards[index].stake"
                        :placeholder="$t('Total stake')"
                        :key="`stake-${index}`"
                        disabled
                      )

                    Col(span="3")
                      span {{ $t('Gem') }}
                    Col(span="7")
                      InputNumber(
                        :step="1"
                        style="width: 72px;"
                        v-model="formData.rewards[index].prize"
                        :placeholder="$t('Input gem')"
                        :key="`gem-${index}`"
                        disabled
                      )
                    Col(span="4")
                      Button.reward-remove(
                        type="error"
                        icon="md-close"
                        size="small"
                        @click="removeReward(index)"
                        disabled
                      )

        .content-part
          Divider(orientation="left")
            Icon(
              type="ios-game-controller-b",
              style="margin-right: 6px"
            )
            span {{ $t('Campaign target audience') }}

          FormItem(prop="operators")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign operators')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.operators"
                  :placeholder="$t('Select campaign operators')"
                )
                  Option(v-for="item in operators" :value="item._id" :key="item._id") {{ item.fullname }}

          FormItem(prop="provider")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign provider')}}
              Col(span="14" offset="1")
                Select(
                  v-model="formData.provider"
                  :placeholder="$t('Select campaign provider')"
                  @on-change="onProvidersChange"
                )
                  Option(v-for="item in providers" :value="item._id" :key="item._id") {{ item.fullname }}

          FormItem(prop="games")
            Row
              Col.title(span="9")
                h3 {{$t('News games')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.games"
                  :placeholder="$t('Select campaign games')"
                )
                  Option(v-for="item in games" :value="item._id" :key="item._id") {{ item.name }}
            Row(style="padding-top: 0px;")
              Col(span="24")
                Button.app-tool-right(
                  type="success",
                  @click="toggleAllGames"
                ) {{$t('Select all')}}

      //- 新增用內容
      template(v-if="editType === 'new'")
        .content-part
          FormItem(prop="type")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign type')}}
              Col(span="14" offset="1")
                Select(
                  v-model="formData.type"
                  :placeholder="$t('Select campaign type')"
                  @on-change="onCampaignTypeChanged"
                )
                  Option(v-for="item in campaignTypes" :value="item.value" :key="item.value") {{ item.label }}

          FormItem(prop="name")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign name')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.name"
                  :placeholder="$t('Input campaign name')"
                )

          FormItem(prop="content")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign content')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.content"
                  type="textarea"
                  :rows="4"
                  :placeholder="$t('Input campaign content')"
                )

        .content-part
          Divider(orientation="left")
            Icon(
              type="md-create",
              style="margin-right: 6px"
            )
            span {{ $t('Campaign schedule settings') }}

          FormItem(prop="startTime")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign startTime')}}
              Col.val(span="10" offset="1")
                DatePicker.full-width.date-picker(
                  type="date"
                  v-model="formData.startTime"
                  :placeholder="$t('Input campaign startTime')"
                  @on-change="onTimePickerChange"
                )
              Col.val(span="4")
                TimePicker.full-width(
                  format="HH:mm"
                  v-model="formData.startTimePicker"
                  :placeholder="$t('Select time')"
                  @on-change="onTimePickerChange"
                )

          FormItem(prop="endTime")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign endTime')}}
              Col.val(span="10" offset="1")
                DatePicker.full-width.date-picker(
                  type="date"
                  v-model="formData.endTime"
                  :placeholder="$t('Input campaign endTime')"
                  @on-change="onTimePickerChange"
                )
              Col.val(span="4")
                TimePicker.full-width(
                  format="HH:mm"
                  v-model="formData.endTimePicker"
                  :placeholder="$t('Select time')"
                  @on-change="onTimePickerChange"
                )

          FormItem(prop="launchTime")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign launchTime')}}
              Col.val(span="10" offset="1")
                DatePicker.full-width.date-picker(
                  type="date"
                  v-model="formData.launchTime"
                  :placeholder="$t('Input campaign launchTime')"
                  @on-change="onTimePickerChange"
                )
              Col.val(span="4")
                TimePicker.full-width(
                  format="HH:mm"
                  v-model="formData.launchTimePicker"
                  :placeholder="$t('Select time')"
                  @on-change="onTimePickerChange"
                )

          FormItem(prop="closeTime")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign closeTime')}}
              Col.val(span="10" offset="1")
                DatePicker.full-width.date-picker(
                  type="date"
                  v-model="formData.closeTime"
                  :placeholder="$t('Input campaign closeTime')"
                  @on-change="onTimePickerChange"
                )
              Col.val(span="4")
                TimePicker.full-width(
                  format="HH:mm"
                  v-model="formData.closeTimePicker"
                  :placeholder="$t('Select time')"
                  @on-change="onTimePickerChange"
                )

          FormItem(prop="cycleTime")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign cycleTime')}}
              Col.val(span="14" offset="1")
                InputNumber.full-width(
                  :min="1"
                  v-model="formData.cycleTime"
                  :formatter="value => `${value} ${ formData.type === 'rankList' ? $t('Day') : $t('Hour') }`"
                  :parser="value => value.replace('Day','')"
                )

        .content-part
          Divider(orientation="left")
            Icon(
              type="logo-usd",
              style="margin-right: 6px"
            )
            span {{ $t('Campaign reward settings') }}

          div(v-if="formData.type === 'rankList'")
            FormItem(prop="ranking")
              Row
                Col.title(span="9")
                  h3 {{$t('Campaign period ranking')}}
                Col.val(span="14" offset="1")
                  InputNumber(
                    :step="10"
                    style="width: 100%;"
                    v-model="formData.ranking"
                    :placeholder="$t('Input campaign ranking')"
                  )

            FormItem(prop="model")
              Row
                Col.title(span="9")
                  h3 {{$t('Campaign model')}}
                Col.val(span="14" offset="1")
                  Input(
                    v-model="formData.model"
                    :placeholder="$t('Input campaign model')"
                    :disabled="true"
                  )

            FormItem(prop="sort")
              Row
                Col.title(span="9")
                  h3 {{$t('Campaign sort')}}
                Col(span="14" offset="1")
                  //- Input(
                  //-   v-model="formData.sort"
                  //-   :placeholder="$t('Input campaign sort')"
                  //- )
                  Select(
                    v-model="formData.sort"
                    :placeholder="$t('Input campaign sort')"
                  )
                    Option(v-for="item in sortList" :value="item.value" :key="item.value") {{ item.label }}

          FormItem(prop="rewardType")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign reward type')}}
              Col(span="14" offset="1")
                //- Input(
                //-   v-model="formData.sort"
                //-   :placeholder="$t('Input campaign sort')"
                //- )
                Select(
                  v-model="formData.rewardType"
                  :placeholder="$t('Campaign reward type')"
                  disabled
                )
                  Option(v-for="item in rewardTypes" :value="item.value" :key="item.value") {{ item.label }}

          FormItem(prop="condition" v-if="formData.type !== 'cumulativeBetting'")
            Row
              Col.title(span="9")
                h3(v-if="formData.type === 'rankList'") {{$t('Campaign condition')}}
                h3(v-if="formData.type === 'fixedBetting'") {{$t('Fixed betting')}}

              Col.val(span="14" offset="1")
                InputNumber(
                  :step="100"
                  style="width: 100%;"
                  v-model="formData.condition"
                  :placeholder="$t('Input campaign condition')"
                  )

          FormItem(prop="rewards")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign rewards')}}

              Col(span="14" offset="1")
                .link-row(v-for="(item, index) in formData.rewards" v-if="formData.type === 'rankList'")
                  Row
                    Col(span="3")
                      span {{ $t("Ranking") }}
                    Col(span="6")
                      InputNumber(
                        :min="1"
                        style="width: 52px;"
                        v-model="formData.rewards[index].rank"
                        :placeholder="$t('Input campaign rank')"
                        :key="`rank-${index}`"
                      )
                    Col(span="3")
                      span {{ $t(formData.rewardType) }}
                    Col(span="8")
                      InputNumber(
                        :step="1000"
                        style="width: 100px;"
                        v-model="formData.rewards[index].prize"
                        :placeholder="$t('Input campaign rewards')"
                        :key="`prize-${index}`"
                      )
                    Col(span="4")
                      Button.reward-remove(
                        type="error"
                        icon="md-close"
                        size="small"
                        @click="removeReward(index)"
                      )
                .link-row(v-for="(item, index) in formData.rewards" v-if="formData.type !== 'rankList'")
                  Row
                    Col(span="3")
                      span {{ $t("Cumulative betting") }}
                    Col(span="7")
                      InputNumber(
                        :step="100"
                        style="width: 72px;"
                        v-model="formData.rewards[index].stake"
                        :placeholder="$t('Total stake')"
                        :key="`stake-${index}`"
                      )

                    Col(span="3")
                      span {{ $t(formData.rewardType) }}
                    Col(span="7")
                      InputNumber(
                        :step="1"
                        style="width: 72px;"
                        v-model="formData.rewards[index].prize"
                        :placeholder="$t('Input gem')"
                        :key="`gem-${index}`"
                      )
                    Col(span="4")
                      Button.reward-remove(
                        type="error"
                        icon="md-close"
                        size="small"
                        @click="removeReward(index)"
                      )

              Col.val(span='24')
                Button(
                  icon="md-add"
                  size="small"
                  type="primary"
                  @click="addReward()"
                )

        .content-part
          Divider(orientation="left")
            Icon(
              type="ios-game-controller-b",
              style="margin-right: 6px"
            )
            span {{ $t('Campaign target audience') }}

          FormItem(prop="operators")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign operators')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.operators"
                  :placeholder="$t('Select campaign operators')"
                )
                  Option(v-for="item in operators" :value="item._id" :key="item._id") {{ item.fullname }}

          FormItem(prop="provider")
            Row
              Col.title(span="9")
                h3 {{$t('Campaign provider')}}
              Col(span="14" offset="1")
                Select(
                  v-model="formData.provider"
                  :placeholder="$t('Select campaign provider')"
                  @on-change="onProvidersChange"
                )
                  Option(v-for="item in providers" :value="item._id" :key="item._id") {{ item.fullname }}

          FormItem(prop="games")
            Row
              Col.title(span="9")
                h3 {{$t('News games')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.games"
                  :placeholder="$t('Select campaign games')"
                )
                  Option(v-for="item in games" :value="item._id" :key="item._id") {{ item.name }}
            Row(style="padding-top: 0px;")
              Col(span="24")
                Button.app-tool-right(
                  type="success",
                  @click="toggleAllGames"
                ) {{$t('Select all')}}

    .footer(slot='footer')
      Button(icon="md-close" @click="onClose" :disabled="isUpdating") {{$t('Cancel')}}
      Button(
        icon="md-checkmark"
        type="primary"
        @click="handleSubmit"
        :loading="isUpdating"
        v-if="loginUserInfo.isAdministrator"
      ) {{$t('Confirm')}}

</template>
<script>
import { mapGetters } from 'vuex'
import NumberUtil from '~m/number-util'
import StringUtil from '~m/string-util'
import Currency from '~m/currency'
import Time from '~m/time'
import moment from 'moment'

export default {
  name: 'campaign-editor',
  mixins: [NumberUtil, StringUtil, Time, Currency],
  data () {
    return {
      isUpdating: false,
      sortList: [
        { value: '-win', label: this.$t('Higest win') },
        { value: '-winRate', label: this.$t('Higest win rate') }
      ],
      campaignTypes: [
        { value: 'rankList', label: this.$t('campaignType.rankList') },
        { value: 'cumulativeBetting', label: this.$t('campaignType.cumulativeBetting') },
        { value: 'fixedBetting', label: this.$t('campaignType.fixedBetting') }
      ],
      rewardTypes: [
        { value: 'point', label: this.$t('Point') },
        { value: 'gem', label: this.$t('Gem') }
      ]
    }
  },
  props: {
    formData: Object,
    loginUserInfo: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  watch: {
    rewardTypes (nv, ov) {
      this.rewardTypes = nv
    },
    async visible (nv, ov) {
      if (this.formData && this.formData.isReady && !this.formData.isDirty && nv) {
        if (!this.formData.startTimePicker) this.formData.startTimePicker = this.formData.startTime
        if (!this.formData.endTimePicker) this.formData.endTimePicker = this.formData.endTime
        if (!this.formData.launchTimePicker) this.formData.launchTimePicker = this.formData.launchTime
        if (!this.formData.closeTimePicker) this.formData.closeTimePicker = this.formData.closeTime
      }
      if (this.editType === 'new') {
        if (this.formData) this.formData.provider = this.providers[0]._id
        if (this.formData) this.formData.operators = this.operators.map(operator => operator._id)
        if (this.formData) this.formData.games = this.games.map(g => g._id)
      }
    },
    formData: {
      async handler (nv, ov) {
        if (!this.formData) return
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('operator', { operators: 'getAllForSelector' }),
    ...mapGetters('provider', { providers: 'getAllForSelector' }),
    ...mapGetters('game', { games: 'getAllForSelector' }),
    campaignEditorRule () {
      switch (this.editType) {
        case 'new':
          return {
            provider: [{ required: true, validator: this.verifySelector(this.$t(`This field is required`)), trigger: 'change' }],
            name: [{ required: true, message: this.$t(`Input campaign name`), trigger: 'blur' }],
            content: [{ required: true, message: this.$t(`Input campaign content`), trigger: 'blur' }],
            startTime: [{ required: true, validator: this.verifyDate('startTime'), trigger: 'blur' }],
            endTime: [{ required: true, validator: this.verifyDate('endTime'), trigger: 'blur' }],
            launchTime: [{ required: true, validator: this.verifyDate('launchTime'), trigger: 'blur' }],
            closeTime: [{ required: true, validator: this.verifyDate('closeTime'), trigger: 'blur' }],
            cycleTime: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
            model: [{ required: true, message: this.$t('Input campaign model'), trigger: 'blur' }],
            sort: [{ required: true, message: this.$t('Input campaign sort'), trigger: 'blur' }],
            type: [{ required: true, message: this.$t('Select campaign type'), trigger: 'blur' }],
            rewardType: [{ required: true, message: this.$t('Campaign reward type'), trigger: 'blur' }],
            condition: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
            ranking: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
            rewards: [{ required: true, validator: this.verifySelector(this.$t(`Field is required`)), trigger: 'blur' }],
            operators: [{ required: true, validator: this.verifySelector(this.$t(`Select campaign operators`)), trigger: 'change' }],
            games: [{ required: true, validator: this.verifySelector(this.$t(`Select campaign games`)), trigger: 'change' }]
          }
        default:
          return {}
      }
    }
  },
  methods: {
    verifyDate (field) {
      return (rule, value, callback) => {
        if (!value || !_.isDate(value)) {
          return callback(new Error(this.$t(`Input campaign ${field}`)))
        }
        callback()
      }
    },
    toggleAllGames () {
      this.formData.games = this.games.map(g => g._id)
    },

    verifyNumber (rule, value, callback) {
      if (!value) {
        return callback(new Error(this.$t(`This field is required`)))
      } else if (!_.isNumber(value)) {
        return callback(new Error(this.$t(`Input number`)))
      }
      callback()
    },

    addReward () {
      let reward = _.clone(_.last(this.formData.rewards))
      if (reward) {
        reward.rank += 1
      } else {
        reward = { rank: 1, prize: 10000 }
      }
      this.formData.rewards.push(reward)
    },
    removeReward (index) {
      this.formData.rewards.splice(index, 1)
    },

    verifySelector (message) {
      return (rule, value, callback) => {
        if (!value || !value.length) {
          return callback(new Error(message))
        }
        callback()
      }
    },

    getOffset (data, index) {
      let offset = null
      const length = data.length - index
      if (index % 4 === 0 && length < 4) {
        offset = {
          marginLeft: `${(4 - length) % 4 * 6 / 24 * 100}%`
        }
      }
      return offset
    },
    handleSubmit () {
      this.$refs.campaignEditorForm.validate(valid => {
        if (!valid) return false
        this.isUpdating = true
        this.parseObjectNumber(this.formData)
        this.onUpdate()
      })
    },
    visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        this.onClose()
      }

      if (this.formData) this.formData.isReady = true
    },
    onCampaignTypeChanged (val) {
      if (this.editType === 'new' && !this.formData.isDirty) {
        this.formData.cycleTime = 24
      }
      if (val === 'rankList') this.formData.rewardType = 'point'
      if (val !== 'rankList') this.formData.rewardType = 'gem'
    },
    onTimePickerChange (val) {
      let fields = ['startTime', 'endTime', 'launchTime', 'closeTime']
      for (let field of fields) {
        let timeField = `${field}Picker`
        let date = moment(this.formData[field]).format('YYYY-MM-DD')
        let time = this.formData[timeField]
        let dateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').toDate()
        this.formData[field] = dateTime
      }
    }
  },
  async mounted () {
    if (this.formData) this.formData.games = this.games.map(g => g._id)
  }
}
</script>
<style lang="sass" scoped>
  .game-edit-title
    height: 40px
    p
      height: auto

  .full-width
    width: 100%

  .align-left
    text-align: left

  .check-item
    cursor: pointer
    color: #666
    i
      margin-right: 8px

    &:hover
      color: darken( #666, 30%)

  .operator-name
    margin: 0 0 4px 4px
    background: #999
    padding: 0 8px
    line-height: 24px
    color: #fff
    display: inline-block

  .reward-item
    margin-bottom: 4px

    .reward-text
      margin-right: 4px
      line-height: 32px

  .reward-remove
    float: right
    margin-top: 2px
    height: 30px
    width: 28px

  .date-picker
    width: 186px
    margin-right: 8px

  .rank-row
    margin-bottom: 8px
</style>
