<template lang="pug">
  Modal(
    :mask-closable="true",
    :styles="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
    v-if="formData"
  )
    p(slot="header")
      span {{ $t("Edit campaign rank") }}

    Form.modal-editor(
      :model="formData"
    )
      template
        .content-part
          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign name')}}
              Col.val(span="18")
                span {{formData.campaign.name}}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign round count')}}
              Col.val(span="18")
                span {{formData.name}}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign startTime')}}
              Col.val(span="18")
                span {{ timeFormat(formData.startTime) }}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign endTime')}}
              Col.val(span="18")
                span {{ timeFormat(formData.endTime) }}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign rank has draw status')}}
              Col.val(span="18")
                span {{ formData.hasDraw ? $t("Already draw") : $t("Not draw") }}

          FormItem
            Row
              Col.title(span="6")
                h3 {{$t('Campaign rank list')}}

              Col.val(span="18")

            Row
              i-table(:columns="rankColumns" :data="formData.ranks")
                template(slot-scope="{ row, index }" slot="rank")
                  span {{ index }}

                template(slot-scope="{ row, index }" slot="win")
                  span {{ currencyFormat(row.win, userInfo) }}

    .footer(slot='footer')
      Button(icon="md-close" @click="onClose") {{$t('Close')}}

</template>
<script>
import { mapGetters } from 'vuex'
import Currency from '~m/currency'
import NumberUtil from '~m/number-util'
import StringUtil from '~m/string-util'
import Time from '~m/time'

export default {
  name: 'campaign-rank-editor',
  mixins: [NumberUtil, StringUtil, Time, Currency],
  data () {
    return {
      isUpdating: false,
      rankColumns: [
        {
          title: this.$t('Rank'),
          slot: 'rank',
          width: 80,
          align: 'center'
        },
        {
          title: this.$t('Username'),
          key: 'username'
        },
        {
          title: this.$t('Win prize'),
          slot: 'win'
        },
        {
          title: this.$t('Win rate'),
          key: 'winRate'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['userInfo'])
  },
  props: {
    formData: Object,
    loginUserInfo: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) return
        // if (!this.formData.provider) this.formData.provider = `${this.providers[0].id}`
        this.parseObjectNumber(this.formData)
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          // console.log(`update isDirty`)
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  methods: {
    visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        this.onClose()
      }

      this.formData.isReady = true
    }
  },
  mounted () {
  }
}
</script>
<style lang="sass" scoped>
  .game-edit-title
    height: 40px
    p
      height: auto

  .full-width
    width: 100%

  .align-left
    text-align: left

  .check-item
    cursor: pointer
    color: #666
    i
      margin-right: 8px

    &:hover
      color: darken( #666, 30%)

  .operator-name
    margin: 0 0 4px 4px
    background: #999
    padding: 0 8px
    line-height: 24px
    color: #fff
    display: inline-block

  .reward-item
    margin-bottom: 4px

    .reward-text
      margin-right: 4px
      line-height: 32px

    .reward-rank
      margin-right: 8px
      width: 56px

    .reward-prize
      width: 72px

    .reward-remove
      display: inline-block
      width: 18px
      height: 18px
      line-height: 18px
      padding: 0
      font-size: 11px
      margin-left: 4px
      text-align: center
</style>
