<script>
// 解析縮圖資料 base url 來源自 .env
export default {
  methods: {
    // 判斷是不是 json 字串
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  }
}
</script>
