<template lang="pug">
.campaign-rank-list
  Table.campaign-rank.app-table(:columns="rankColumns", :data="rankList", border, v-if="rankList && !campaignRankListLoading")
    template(slot-scope="{ row, index }" slot="rank")
      span {{ index + 1 }}

    template(slot-scope="{ row, index }" slot="operator")
      span {{ row.operator.name || row.operator.fullname }}

    template(slot-scope="{ row, index }" slot="win")
      span {{ currencyFormat(row.win, userInfo) }}

    template(slot-scope="{ row, index }" slot="winRate")
      span {{ row.winRate || row.win / row.totalStake }}

    template(slot-scope="{ row, index }" slot="date")
      span {{ timeFormat(row.createdAt) }}

    template(slot-scope="{ row, index }" slot="prize")
      span {{ getRewardPrize(index) }}

  Col.table-loading-col(span='24', v-if="campaignRankListLoading")
    Spin(fix, :style="{ backgroundColor: 'transparent', marginTop: '0' }")
      Icon(type="ios-loading" size=18 class="spin-icon")
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Currency from '~m/currency'
import Time from '~m/time'
import Scroll from '~m/scroll'
import NumberUtil from '~m/number-util'
// import to from 'await-to-js'

export default {
  name: 'campaign-rank-list',
  data () {
    return {
      rankColumns: [
        {
          title: this.$t('Rank'),
          slot: 'rank',
          width: 80,
          align: 'center'
        },
        {
          title: this.$t('Username'),
          key: 'username'
        },
        {
          title: this.$t('Operator'),
          slot: 'operator'
        },
        {
          title: this.$t('Draw date'),
          slot: 'date'
        },
        {
          title: this.$t('Win prize'),
          slot: 'win'
        },
        {
          title: this.$t('Win rate'),
          slot: 'winRate'
        },
        {
          title: this.$t('Prize'),
          slot: 'prize'
        }
      ]
    }
  },
  props: {
    rewards: Array,
    campaignLinkId: Number
  },
  mixins: [Currency, Scroll, NumberUtil, Time],
  components: {},
  watch: {
    // campaignLinkId (nv, ov) {
    //   if (nv) this.findCampaignRank({ id: nv })
    // }
  },
  computed: {
    ...mapGetters('campaignRank', { campaignRankData: 'getOneData', campaignRankListLoading: 'getLoadingOneStatus' }),
    rankList () {
      if (!this.campaignRankData) return
      return this.campaignRankData.ranks
    }
  },
  methods: {
    ...mapActions('campaignRank', { findCampaignRank: 'findOne' }),
    getRewardPrize (index) {
      let rewards = _.keyBy(this.rewards, 'rank')
      let rank = index + 1
      let prize = 0
      while (!prize && rank >= 1) {
        if (rewards[`${rank}`]) prize = rewards[`${rank}`].prize
        rank--
      }
      return this.currencyFormat(prize, this.userInfo)
    },
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('campaignRank/update', { id: row._id, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' } }),
        h('Icon', { slot: 'close', props: { type: 'md-close' } })
      ])
    }
  },
  mounted () {
    this.findCampaignRank({ id: this.campaignLinkId })
  }
}
</script>
<style lang="sass" scoped>
.ivu-table-expanded-cell
  padding: 16px
</style>
